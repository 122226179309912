<template lang="html">
  <div class="complaint">
    <div class="box">
      <!-- <div class="list">
        <div class="left">
          投诉人
        </div>
        <div class="right">
          <van-field v-model="params.merchants_name" clearable input-align="right" placeholder="请输入商户名字" />
        </div>
      </div> -->
      <div class="list">
        <div class="left">投诉对象</div>
        <div class="right">
          <van-field
            v-model="params.worker_name"
            clearable
            input-align="right"
            placeholder="请输入投诉对象"
          />
        </div>
      </div>
      <div class="list">
        <div class="left">投诉问题</div>
        <div class="right">
          <van-field
            v-model="params.complaint_reason"
            clearable
            input-align="right"
            placeholder="请输入投诉问题"
          />
        </div>
      </div>
      <div class="cause">
        <div class="title">投诉原因</div>
        <div class="content">
          <van-field
            v-model="params.complaint_content"
            type="textarea"
            placeholder="请输入投诉原因"
          />
        </div>
      </div>
      <div class="evidence">
        <div class="title">
          <div class="left">图片证据<span>(选填)</span></div>
          <div class="right">{{ params.complaint_images.length }}<span>/9</span></div>
        </div>
        <div class="content">
          <div class="imgList" v-for="(item, index) in params.complaint_images" :key="index">
            <img :src="imgUrl + item" class="coverImg" alt="" />
            <img src="../../assets/image/del.png" @click="closeImg(index)" class="close" alt="" />
          </div>
          <div class="imgList" v-if="params.complaint_images.length < 9">
            <van-uploader :after-read="afterRead"> </van-uploader>
          </div>
        </div>
      </div>
    </div>
    <div class="btn">
      <van-button type="primary" round @click="affirm" style="color: #ffffff">提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'complaint',
  data() {
    return {
      params: {
        merchants_name: '',
        worker_name: '',
        complaint_reason: '',
        complaint_content: '',
        complaint_images: []
      },
      imgUrl: process.env.VUE_APP_IMG_URL
    };
  },
  methods: {
    afterRead(files) {
      if (files && files.file) {
        let formData = new FormData();
        formData.append('file', files.file);
        this.$require.HttpPost('/system/upload/upImgs', formData).then((res) => {
          this.params.complaint_images.push(res.data.fileInfos[0].hostUrl);
        });
      }
    },
    closeImg(index) {
      this.params.complaint_images.splice(index, 1);
    },
    affirm() {
      // if (!this.params.merchants_name) {
      //   this.$toast('请输入投诉人');
      //   return;
      // }
      if (!this.params.worker_name) {
        this.$toast('请输入投诉对象');
        return;
      }
      if (!this.params.complaint_reason) {
        this.$toast('请输入投诉问题');
        return;
      }
      this.$require.HttpPost('/h5/complaint/addComplaint', this.params).then((res) => {
        this.$toast('投诉成功');
        setTimeout(() => {
          this.$router.go(-1);
        }, 500);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.complaint {
  padding: 0 24px 50px 24px;
  .box {
    margin-top: 30px;
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    padding: 10px 20px 50px 20px;
    .list {
      display: flex;
      justify-content: space-between;
      height: 106px;
      align-items: center;
      border-bottom: 2px solid #efefef;
      font-size: 28px;
      .left {
        color: #000000;
      }
      .right {
        color: #333333;
      }
    }
    .cause {
      font-size: 28px;
      .title {
        height: 86px;
        line-height: 86px;
        color: #000000;
      }
      .content {
        .van-cell {
          background: #f8f8f8;
          border-radius: 15px;
          padding: 20px;
        }
      }
    }
    .evidence {
      margin-top: 30px;
      .title {
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        span {
          color: #d2d2d2;
        }
      }
      .content {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        .imgList {
          width: 202px;
          height: 202px;
          border-radius: 15px;
          margin-right: 25px;
          margin-bottom: 20px;
          position: relative;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .coverImg {
            width: 202px;
            height: 202px;
            border-radius: 15px;
          }
          .close {
            width: 38px;
            height: 38px;
            position: absolute;
            right: 0;
            top: 0;
          }
          .custom {
            width: 202px;
            height: 202px;
            background: #f8f8f8;
            border-radius: 15px;
            img {
              width: 48px;
              height: 48px;
              display: block;
              margin: 0 auto;
              padding-top: 77px;
            }
          }
        }
      }
    }
  }
  .btn {
    width: 468px;
    height: 96px;
    margin: 60px auto 0 auto;
    color: #ffffff;
  }
}
</style>
